.code-grid-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
    grid-template-rows: 200px min-content auto;
  place-items: center;
  justify-self: center;
  max-height: 100vh;
  /* width: 100%; */
  margin: 1rem auto;
  margin: 1rem;
  /* margin-bottom: 1rem; */
}

.form-row {
  display: inline-grid;
  gap: 1rem;
  grid-template-rows: 0.75fr 0.25fr;
  justify-self: center;
  align-self: center;
  /* height: 100%; */
  width: 100%;
  background-color: rgb(219, 219, 219);
  border-radius: 7px;
  max-height: 300px;
  min-height: 200px;
}

.form {
  margin: 0 auto;
  margin: 1.5rem;
}

.price-button {
  justify-self: right;
  align-self: center;
  margin: 1.5rem;
  height: 50px;
}
.buttons-row {
  display: flex;
  justify-self: left;
  align-self: center;
  width: 100%;
  max-height: 500px;
  min-height: 50px;
  height: max-content;
}

.back-button {
  align-self: center;
}

.invel-logo{
    height: auto;
    width: auto;
    max-height: 30px;
}

.invel-logo-row {
  grid-row: 3 span 1;
  /* centramos imagen */
  display: flex;
  justify-content: center;
  text-align: center;

  /* width: 100%; */
  height: 100%;
  max-height: 45px;
  justify-self: center;
  align-self: center;
  margin: 0 auto;
}
body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  /* margin-bottom: 1rem; */
  /* display: flex; */
  /* width: 100%; */
  /* height: min-content;
  max-height: 35px;
  min-height: 20px; */
  /* margin: 1rem auto; */
}

footer > img {
  height: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.stock-table{
    margin-left: 2rem;
    margin-right: 2rem;
}

.loading-container {
  height: 100%;
  display: grid;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  grid-template-rows: 50px 40px;
  justify-content: center;
  align-content: center;
}

.loading-spinner {
  justify-self: center;
  align-self: center;
}
.loading-text {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}

.home-grid-container {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-rows: 200px -webkit-min-content auto;
  grid-template-rows: 200px min-content auto;
  grid-template-columns: 1fr;
  place-items: center;
  max-height: 100vh;
  /* width: 100%; */
  margin: 1rem;
}

.scanner-container-row {
  display: inline-grid;
  /* grid-template-rows: repeat(2, auto-fit); */
  /* gap: 1rem; */
  place-items: center;
  justify-self: center;
  background-color: rgb(219, 219, 219);
  border-radius: 7px;
  height: -webkit-min-content;
  height: min-content;
  width: 100%;
  margin: 0 auto;
  min-height: 360px;
  max-height: 500px;
}

.scanner-row {
  justify-self: center;
  align-self: center;
}
/* 
.text-row {
  justify-self: center;
  align-self: center;
} */

.text-row > p {
  text-align: center;
  margin: 0.5rem;
}

.invel-logo-row {
  grid-row: 3 span 1;
  /* centramos imagen */
  display: flex;
  justify-content: center;
  text-align: center;

  /* width: 100%; */
  height: 100%;
  /* max-height: 45px;
  min-height: 40px; */
  justify-self: center;
  align-self: center;
  margin: 0 auto;
}

.invel-logo {
  height: auto;
  width: auto;
  max-height: 30px;
}

.client-logo-row {
  /* centramos imagen */
  display: flex;
  justify-content: center;
  text-align: center;

  /* background-color: rgb(219, 219, 219); */
  /* width: 100%; */
  /* height: min-content; */
  max-height: 200px;
  min-height: 100px;
  justify-self: center;
  align-self: center;
  border-radius: 7px;
}

.client-logo {
  min-height: 100px;
  max-height: 200px;
  max-width: 300px;
}

.button-row {
  /* width: 100%;
  height: 100%; */
  justify-self: center;
  align-self: center;
  max-height: 100px;
  min-height: 50px;
}

.code-grid-container {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: 1fr;
    grid-template-rows: 200px -webkit-min-content auto;
    grid-template-rows: 200px min-content auto;
  place-items: center;
  justify-self: center;
  max-height: 100vh;
  /* width: 100%; */
  margin: 1rem auto;
  margin: 1rem;
  /* margin-bottom: 1rem; */
}

.form-row {
  display: inline-grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-rows: 0.75fr 0.25fr;
  justify-self: center;
  align-self: center;
  /* height: 100%; */
  width: 100%;
  background-color: rgb(219, 219, 219);
  border-radius: 7px;
  max-height: 300px;
  min-height: 200px;
}

.form {
  margin: 0 auto;
  margin: 1.5rem;
}

.price-button {
  justify-self: right;
  align-self: center;
  margin: 1.5rem;
  height: 50px;
}
.buttons-row {
  display: flex;
  justify-self: left;
  align-self: center;
  width: 100%;
  max-height: 500px;
  min-height: 50px;
  height: -webkit-max-content;
  height: max-content;
}

.back-button {
  align-self: center;
}

.invel-logo{
    height: auto;
    width: auto;
    max-height: 30px;
}

.invel-logo-row {
  grid-row: 3 span 1;
  /* centramos imagen */
  display: flex;
  justify-content: center;
  text-align: center;

  /* width: 100%; */
  height: 100%;
  max-height: 45px;
  justify-self: center;
  align-self: center;
  margin: 0 auto;
}
.product-grid-container {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, auto-fit) auto;
  place-items: center;
  height: calc(100vh - 100px);
  /* min-height: 820px; size de un iphone 11 */
  margin: 1rem;
}

.prod-container {
  display: flex;
  justify-content: center;
  text-align: center;

  width: 100%;
  /* height: max-content; */
  margin: 0 auto;
  max-height: 300px;
  min-height: 200px;
}
.client-logo {
  min-height: 100px;
  max-height: 200px;
  max-width: 300px;
}
.prod-image {
  max-height: 300px;
  min-height: 250px;
  /* background-color: rgb(219, 219, 219); */
}
.prod-data {
  justify-self: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: rgb(219, 219, 219);
  border-radius: 7px;
}

.prod-name-text {
  font-size: 32px;
  font-weight: 500;
  font-style: italic;
  text-align: center;
  margin-top: 0.3rem;
  margin-bottom: 0;
}

.prod-data-text {
  font-size: 25px;
  font-weight: 400;
  margin: 0.3rem;
  text-align: center;
}
/* 
.name-text {
  font-size: 25px;
  font-weight: 450;
  margin: 0.3rem;
  text-align: center;
}
.sku-text {
  font-size: 25px;
  font-weight: 450;
  margin: 0.3rem;
  text-align: center; */
/* } */

.prod-price {
  display: flex;
  justify-self: center;
  align-self: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgb(219, 219, 219);
  border-radius: 7px;
  text-align: center;
}

.prod-price > p {
  font-size: 50px;
  font-weight: 600;
  margin: 0 auto;
}

.prod-stock {
  justify-self: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: rgb(219, 219, 219);
  border-radius: 7px;
}

.stock-title {
  text-align: center;
  font-weight: 500;
  margin: 0.5rem;
}

.stock-text {
  text-align: center;
  font-weight: 500;
  margin: 0;
  font-size: 16px;
}

.prod-back-btn {
  justify-self: center;
  align-self: center;
  height: 100%;
}

.scroll-down-footer {
  position: absolute;
  left: 0;
  bottom: 0;
}
/* 
.scroll-down-btn-container {
  width: 100%;
  display: flex;
  justify-content: right;
} */

.btn-icon {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
  margin-right: 1rem;
  margin-bottom: 1rem;
  height: 45px;
  width: 45px;
}


.home-grid-container {
  display: grid;
  gap: 1rem;
  grid-template-rows: 200px min-content auto;
  grid-template-columns: 1fr;
  place-items: center;
  max-height: 100vh;
  /* width: 100%; */
  margin: 1rem;
}

.scanner-container-row {
  display: inline-grid;
  /* grid-template-rows: repeat(2, auto-fit); */
  /* gap: 1rem; */
  place-items: center;
  justify-self: center;
  background-color: rgb(219, 219, 219);
  border-radius: 7px;
  height: min-content;
  width: 100%;
  margin: 0 auto;
  min-height: 360px;
  max-height: 500px;
}

.scanner-row {
  justify-self: center;
  align-self: center;
}
/* 
.text-row {
  justify-self: center;
  align-self: center;
} */

.text-row > p {
  text-align: center;
  margin: 0.5rem;
}

.invel-logo-row {
  grid-row: 3 span 1;
  /* centramos imagen */
  display: flex;
  justify-content: center;
  text-align: center;

  /* width: 100%; */
  height: 100%;
  /* max-height: 45px;
  min-height: 40px; */
  justify-self: center;
  align-self: center;
  margin: 0 auto;
}

.invel-logo {
  height: auto;
  width: auto;
  max-height: 30px;
}

.client-logo-row {
  /* centramos imagen */
  display: flex;
  justify-content: center;
  text-align: center;

  /* background-color: rgb(219, 219, 219); */
  /* width: 100%; */
  /* height: min-content; */
  max-height: 200px;
  min-height: 100px;
  justify-self: center;
  align-self: center;
  border-radius: 7px;
}

.client-logo {
  min-height: 100px;
  max-height: 200px;
  max-width: 300px;
}

.button-row {
  /* width: 100%;
  height: 100%; */
  justify-self: center;
  align-self: center;
  max-height: 100px;
  min-height: 50px;
}

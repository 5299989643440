body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  /* margin-bottom: 1rem; */
  /* display: flex; */
  /* width: 100%; */
  /* height: min-content;
  max-height: 35px;
  min-height: 20px; */
  /* margin: 1rem auto; */
}

footer > img {
  height: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
